
import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "../store";
import { CreateSupportRequestRequest, SupportRequestType, sendSupportRequest } from "./supportRequestWebServices";
import { pushError } from "@/core";

@Component({
  components: {
  }
})
export default class CreateRequest extends Vue {
  request: CreateSupportRequestRequest = {
    senderEmail: store.getters.module.user?.email,
    type: SupportRequestType.Suggestion
  } as CreateSupportRequestRequest;
  isLoading = false;

  get typeOptions() {
    return [
      { text: this.$t("Ask a question"), value: SupportRequestType.Suggestion },
      { text: this.$t("Report spam/abuse"), value: SupportRequestType.SpamOrAbuse },
      { text: this.$t("Other"), value: SupportRequestType.Other }
    ]
  }

  get isUserLoggedIn() {
    return !!store.getters.module.user;
  }

  mounted() {
      if(this.$route.params.aboutUserId) {
        this.request.aboutUserId = this.$route.params.aboutUserId;
        this.request.type = SupportRequestType.SpamOrAbuse;
      }
  }

  onSubmit() {
    this.isLoading = true;
    sendSupportRequest(this.request)
      .then(() => {
        this.$toasted.success(this.$t("Your request has been successfully sent. We will get back to you as soon as possible.").toString(), {
            duration: 5000,
        });
        this.$router.push('/')
            .catch(() => { /* noop */ });
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }

}
