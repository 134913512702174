
import Vue from "vue";
import Component from "vue-class-component";
import CreateRequest from "@/support/CreateRequest.vue";
import store from "@/store";

@Component({
  components: {
    CreateRequest
  },
})
export default class CreateSupportRequest extends Vue {
  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Contact support"));
  }

  get breadcrumbsList() {
    return  [
        {
          text: this.$t('Home'),
          href: '/'
        },
        {
          text: this.$t('Feedback/Suggestion'),
          href: '#'
        }
      ]
  }
}
