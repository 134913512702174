import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/supportrequest`;

export enum SupportRequestType
{
    Suggestion,
    SpamOrAbuse,
    Other
}

export interface CreateSupportRequestRequest
{
    type: SupportRequestType;
    content: string;
    senderEmail: string;
    aboutUserId: string;
}

export function sendSupportRequest(model: CreateSupportRequestRequest) {
    return axios.default.post<string>(`${url}`, model);
}
